<template>
	<section class="container">
		<UserRole />
	</section>
</template>

<script>
import ChangeEmail from '@/modules/general/user/components/ChangeEmail'
import UserRole from '@/modules/general/user/components/UserRole'
import ChangePassword from '@/modules/general/user/components/ChangePassword'
import CreateAccount from '@/modules/general/user/components/CreateAccount'

export default {
	name: 'account',
	components: {
		ChangeEmail,
		ChangePassword,
		CreateAccount,
		UserRole,
	},
	data() {
		return {
			clicked: false,
			password: null,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			await this.$store.dispatch('userVuex/updateUser', {
				id: this.id,
				data: this.user,
			})
			this.toast('Success', 'Brugeren er nu opdateret', true)
			this.clicked = false
		},
	},
	computed: {
		companyOptions() {
			var options = this.companies.map((item) => {
				return {
					text: item.companyInfo.name,
					value: item.id,
				}
			})
			return options
		},
		companies() {
			var companies = this.$store.getters['company/companies']
			return companies
		},
		userAuth() {
			return this.$store.getters['userVuex/userAuth']
		},
		userAuthEmail() {
			return this.userAuth?.email ?? null
		},
		isMe() {
			return this.id == this.myUid
		},
		myUid() {
			return this.$store.getters['auth/uid']
		},
		user() {
			return this.$store.getters['userVuex/user']
		},
		id() {
			return this.$route.params.id
		},
	},
}
</script>

<style></style>
