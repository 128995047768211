<template>
	<section>
		<template v-if="$can('update', 'User', 'Role')">
			<div class="row">
				<div class="col-12">
					<h3>Roller</h3>
				</div>
			</div>

			<div class="row">
				<div class="col-auto pr-0">
					<InputCheck v-model="user.role.isAdmin" :flip="true" :disabled="!isAdmin"/>
				</div>
				<div class="col-auto pl-0 mt-1">
					<p>{{ $t('global.roles.superAdmin') }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col-auto pr-0">
					<InputCheck v-model="user.role.isAdvisor" :flip="true" :disabled="userIsEmployeeOrCompanyAdmin" />
				</div>
				<div class="col-auto pl-0 mt-1">
					<p>{{ $t('global.roles.advisor') }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col-auto pr-0">
					<InputCheck v-model="user.role.isCompanyAdmin" :flip="true" :disabled="true" />
				</div>
				<div class="col-auto pl-0 mt-1">
					<p>{{ $t('global.roles.companyAdmin') }}</p>
				</div>
			</div>
			<div class="row" v-if="user.role.isCompanyAdmin">
				<div class="col-lg-6 col-12">
					<InputDropdown
						v-model="user.role.companyAdminCompanies"
						v-if="user.role.isCompanyAdmin"
						:options="companyOptions"
						:multiple="true"
						:disabled="true"
					>
					</InputDropdown>
				</div>
			</div>

			<div class="row">
				<div class="col-auto pr-0">
					<InputCheck v-model="user.role.isEmployee" :flip="true" :disabled="true" />
				</div>
				<div class="col-auto pl-0 mt-1">
					<p>{{ $t('global.roles.employee') }}</p>
				</div>
			</div>
			<div class="row" v-if="user.role.isEmployee">
				<div class="col-lg-6 col-12">
					<InputDropdown v-model="user.role.employeeCompanies" v-if="user.role.isEmployee" :options="companyOptions" :multiple="true" :disabled="true">
					</InputDropdown>
				</div>
			</div>
		</template>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" @click.native="onSubmit" type="button" id="signupButton" class="mt-2">
			{{ $t('global.button.update') }}
		</BaseActionButton>
	</section>
</template>

<script>
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'

export default {
	name: 'account',
	components: {
		InputCheck,
		InputDropdown,
	},
	data() {
		return {
			clicked: false,
			password: null,
		}
	},
	methods: {
		async onSubmit() {
			try {
				this.clicked = true
				await this.$store.dispatch('userVuex/updateUser', {
					id: this.id,
					data: this.user,
				})
				this.toast('Success', 'Brugerens roller er nu opdateret', true)
				this.clicked = false
			} catch (err) {
				this.clicked = false
				console.log(err.data)
				this.toast('Fejl', err, false)
			}
		},
	},
	computed: {
		userIsEmployeeOrCompanyAdmin(){
			return this.user.role.isEmployee || this.user.role.isCompanyAdmin
		},
		companyOptions() {
			var options = this.companies.map((item) => {
				return {
					text: item.companyInfo.name,
					value: item.id,
				}
			})
			return options
		},
		companies() {
			var companies = this.$store.getters['company/companies']
			return companies
		},
		userAuth() {
			return this.$store.getters['userVuex/userAuth']
		},
		userAuthEmail() {
			return this.userAuth?.email ?? null
		},
		isMe() {
			return this.id == this.myUid
		},
		myUid() {
			return this.$store.getters['auth/uid']
		},
		user() {
			return this.$store.getters['userVuex/user']
		},
		id() {
			return this.$route.params.id
		},
		isAdvisor() {
			return this.user.role.isAdvisor
		},
		iamAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
	},
}
</script>

<style></style>
